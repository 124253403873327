import React, {Component} from 'react';
import AuthActions from '../../Actions/AuthActions';
import './_header.scss';
import {ConfirmationPopupMessage} from '../Popup/Popup';

class Header extends Component {

    logOut = (e) => {
        e.preventDefault();
        ConfirmationPopupMessage(() => AuthActions.logout(), 'Log Out');
    };

    render() {
        return (
            <header>
                <div className="header">
                    <img className='header-logo' src='/Images/header-logo-text.png' alt="logo"/>
                    {localStorage.token && (
                        <button className="logout-block" onClick={this.logOut}>
                            <img src= '/Images/Icons/log-out-icon.png' alt='loguot'/>
                            <span>Log Out</span>
                        </button>
                    )}
                </div>
            </header>
        );
    }

}

export default Header;