import React from 'react';
import './_forgotPassword.scss';
import {Link} from "react-router-dom";
import AuthActions from "../../Actions/AuthActions"

class ForgotPassword extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        let data = {};
        data.email = this.refs.email.value;
        AuthActions.forgotPassword(data);
    };

    render() {
        return (
            <div className="login-page forgot-password">
                <form onSubmit={this.handleSubmit}>
                    <div className='password-image'>
                        <img src='/Images/Icons/forgot-password-icon.png' alt=''/>
                    </div>
                    <h3>Forgot Password?</h3>
                    <p>Please enter the E-mail that you used to sign in.</p>
                    <div className="input-wrapper">
                        <div className="logins-wrapper">
                            <input placeholder="E-mail" type="email" id="email" name="email" required ref="email"/>
                        </div>
                    </div>
                    <div className="auth-form-actions">
                        <button className="main-button" type="submit">Reset Password</button>
                        <Link to='/login'>Back</Link>
                    </div>
                </form>
            </div>
        );
    }
}

export default ForgotPassword;
