import React, {Component} from 'react';
import ImageBlock from './ImageBlock'
import WorkerBlock from './WorkerBlock'
import Dropzone from "react-dropzone";
import PopupMessage, {SmallPopupMessage} from "../Popup/Popup";
import './_galleryBlock.scss';

class GalleryBlock extends Component {

    constructor(props) {
        super(props);

        this.workerRefs = [];

        this.state = {
            gallery: [],
            workers: [],
            image: '',
            lng: undefined,
            acceptedFiles: [],
            rejectedFiles: []
        };
    }

    static getDerivedStateFromProps(props, state) {

        if (props !== state) {
            return {
                gallery: props.gallery ? props.gallery : [],
                workers: props.workers ? props.workers : [],
                lng: props.lng ? props.lng : undefined,
                image: props.image ? props.image : ''
            };
        }

        return null;
    }

    //// get WIDTHxHEIGHT from upload files.
    // resolutionCheck = (acceptedFiles) => {
    //     new Promise(resolve => {
    //         let allArr = [];
    //         acceptedFiles.map(file => {
    //             const img = new Image();
    //             img.src = window.URL.createObjectURL(file);
    //             img.onload = function () {
    //                 const width = img.naturalWidth,
    //                     height = img.naturalHeight;
    //                 let arr = {
    //                     width: width,
    //                     height: height
    //                 };
    //                 allArr.push(arr);
    //             };
    //         });
    //         resolve(allArr);
    //     }).then(result => {
    //         console.log(result);
    //     });
    // };

    handleGalleryDelete = (index) => {
        this.props.handleDelete(this.state.gallery[index])
    };

    handleImageDelete = () => {
        this.props.handleDelete(this.state.image)
    };

    handleWorkerDelete = (index) => {
        this.props.handleDelete(this.state.workers[index])
    };

    onDrop = (acceptedFiles, rejectedFiles) => {

        this.setState({acceptedFiles, rejectedFiles});
        this.props.handleUpload(this.state.acceptedFiles);

        if (rejectedFiles.length > 0 && acceptedFiles.length > 0) {
            let countOfRejected = rejectedFiles.length;
            SmallPopupMessage("warning", `${countOfRejected} ${countOfRejected === 1 ? 'file' : 'files'} is not uploaded.`, 2000);
        } else if (rejectedFiles.length === 1) {
            if (rejectedFiles[0].type !== 'image/jpeg' && rejectedFiles[0].type !== 'image/png') {
                PopupMessage("error", "JPG or PNG are valid.", 1500);
            } else if (rejectedFiles[0].size > 1000000) {
                PopupMessage("error", "Max. 1mb", 1500);
            }
        } else if (rejectedFiles.length > 1) {
            PopupMessage("error", "Selected images is not a valid.", 1500);
        }
    };

    updateWorkersData = () => {

        let newWorkers = [...this.state.workers];
        newWorkers.forEach((worker, i) => {
            worker.name[this.state.lng] = this.workerRefs[i].refs[`Name${this.state.lng}`] ? this.workerRefs[i].refs[`Name${this.state.lng}`].value : "";
            worker.position[this.state.lng] = this.workerRefs[i].refs[`Position${this.state.lng}`] ? this.workerRefs[i].refs[`Position${this.state.lng}`].value : ""
        });

        this.setState({
            workers: [...newWorkers]
        });
    };

    render() {

        const {gallery, workers, lng, image} = this.state;

        return (
            <div className='my-3'>
                {gallery ? <h5>Slider Images</h5> : <h5>Worker Images</h5>}
                <div className='gallery'>
                    <div className='gallery-head'>
                        <Dropzone onDrop={this.onDrop} accept="image/jpeg, image/png" multiple={this.props.multiple} maxSize={1000000}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <div className='dropzone-text'>
                                        <p className='upload'>Upload Image</p>
                                        <p className='accepted-formats'>Accepted formats and sizes.</p>
                                        <p className='text-purple'>.jpeg, .png, (max 1mb, 2160x1200)</p>
                                    </div>
                                    <input {...getInputProps()} />
                                    {/*<button className='main-button'>Upload</button>*/}
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className='img-list'>
                        {gallery && gallery.map((img, i) =>
                            <ImageBlock key={i} source={img} index={i} handleDelete={this.handleGalleryDelete}/>
                        )}
                        {workers && workers.map((worker, i) =>
                            <WorkerBlock key={`${i}${lng}`}
                                         lng={lng}
                                         index={i}
                                         worker={worker}
                                         ref={ref => ref !== null && !this.workerRefs.includes(ref) && this.workerRefs.push(ref)}
                                         updateWorkersData={this.updateWorkersData}
                                         handleDelete={this.handleWorkerDelete}/>
                        )}
                        {image &&
                            <ImageBlock source={image} handleDelete={this.handleImageDelete}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default GalleryBlock;