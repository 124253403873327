import React from 'react';
import {languages} from "../../Constants/Constants";
import './_faq.scss';

class Faq extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            createSection: false,
            newSection: {}
        }
    }

    addNewSection = () => {
        const {createSection,newSection} = this.state;
        const {collectSection} = this.props;

        if (!createSection) {
            this.setState({createSection: !createSection})
        }
        else{
            let x = [];
            languages.map(e => {
               x.push(e.lngKey)
            });

            for (let i=0; i<x.length; i++) {
                if (!newSection[x[i]]){
                    return false;
                }
            }

            collectSection(newSection);

            this.setState({
                createSection: !createSection,
                newSection: {}
            });
        }
    };

    nameNewSection = async (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;

        await this.setState({
            newSection:{...this.state.newSection,[inputName]:inputValue}
        });
    };

    // handleInputChange = () => {
    //     const {createSection, newSection} = this.state;
    //     const {addSections} = this.props;
    //
    //     if (createSection) {
    //         // let sizeofObject = Object.keys(newSection).length;
    //         // let select = document.getElementsByTagName('select');
    //         //
    //         // let opt = document.createElement('option');
    //         // let arr = [];
    //         // languages.map(e => {
    //         //     opt.appendChild(document.createTextNode(`${newSection[e.lngKey]}`));
    //         //     opt.value = newSection[e.lngKey];
    //         //     arr.push(opt.value);
    //         // });
    //         // console.log(arr);
    //         //
    //         // let num = 0;
    //         // for (let i = 0; i < select.length; i++) {
    //         //     num = num === 2 ? 0 : num;
    //         //     select[i].appendChild(arr[num]);
    //         //     num++;
    //         // }
    //         addSections(newSection);
    //     }
    //
    //     this.setState({
    //         createSection: !createSection,
    //     });
    // };

    // addSection = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //
    //     let obj = {...this.state.newSection, [name]:value};
    //
    //     this.setState({
    //         newSection: obj
    //     });
    // };



    // handleChange = (e) => {
    //     let section = e.target.value;
    //     let {lng, selectSection} = this.props;
    //
    //     let currentIndex = e.nativeEvent.target.selectedIndex;
    //     selectSection(section, lng, currentIndex);
    // };


    render() {
        let {createSection} = this.state;
        const {sections, lng} = this.props;


        return (
            <div className='section'>

                    {!createSection ? (
                        <div className='field input'>
                            <span className='field-name'>Section*</span>
                            <select className='selectSection'>
                                {sections && sections.length > 0 && sections.map((e,i) =>
                                    <option key={i} value={e[lng]}>{e[lng]}</option>
                                )}
                            </select>
                        </div>
                    ) : (
                        <div>
                            <span className='field-name'>Section*</span>
                            <div className='section-inputs'>
                                {languages.map((e,i) =>
                                    <input className='text-input' type='text'
                                           key={i}
                                           placeholder={e.lngName}
                                           defaultValue={''}
                                           name={e.lngKey}
                                           required
                                           onChange={this.nameNewSection}/>
                                )}
                            </div>
                        </div>
                        )}
                <button onClick={this.addNewSection} className='main-button'>{!createSection ? 'New Section' : 'Add'}</button>
            </div>
        )
    }
}

export default Faq;