import React, {Component} from 'react';
import '../Styles/_notFound.scss';
import {Link} from "react-router-dom";


class NotFound extends Component {

    render() {
        return (
            <div className='error-block'>
                <span className={'title'}>404</span>
                <div className={'info'}>
                    <h2>We couldn’t find this page.</h2>
                    <Link to={'/home'} className={'link'}>Home</Link>
                </div>
            </div>
        )
    }
}

export default NotFound;
