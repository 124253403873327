import React, {Component, Fragment} from 'react';
import instance, {setRequestHeader} from "../../Services/Api";
import Buttons from '../Buttons/Buttons';
import PageBlock from '../PageBlock/PageBlock';
import Loading from '../Loading/Loading'
import axios from "axios";
import {BlockDataType, removeFilesFromServer} from "../../Constants/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PopupMessage, {SmallPopupMessage} from '../Popup/Popup';
import './_page.scss';

class Page extends Component {

    constructor(props) {
        super(props);

        this.blockRefs = [];
        this.pageName = this.props.location.pathname;
        this.newData = {
            data: new BlockDataType('-top')
        };

        this.state = {
            data: new BlockDataType('listing'),
            blocks: [],
            isLoaded: false,
            firstLoad: false,
            pageTitle: null,
            filesForDelete: [],
            newFaqSections : []
        };
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.getData();
        } else {
            this.setState({
                isLoaded: true
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            this.getData();
        }
    }

    componentWillUnmount() {
        this.getCall.cancel('Operation canceled due to new request.');
    }

    getData() {

        // cancel the previous request
        if (typeof this.getCall !== typeof undefined) {
            this.getCall.cancel('Operation canceled due to new request.')
        }

        // save the new request for cancellation
        this.getCall = axios.CancelToken.source();


        this.blockRefs = [];
        this.pageName = this.props.location.pathname !== '/' ? this.props.location.pathname : '/home';
        let link = `pages${this.pageName}`;

        this.setState({
            isLoaded: false,
            blocks: []
        });

        instance.get(link, {cancelToken: this.getCall.token})
            .then((response) => {
                this.setState({
                    pageTitle: response.data.title,
                    data: response.data.save_data ? response.data.save_data : response.data.data,
                    isLoaded: true
                });

                this.setState({
                    blocks: this.state.data.blocks ? this.state.data.blocks : []
                });

                // console.log(response.data);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                    //PopupMessage('error', error.toString(), false, true);
                } else {
                    console.log(error);
                    PopupMessage('error', error.toString(), false, true);
                }
            });
    }

    addNewBlock = () => {

        this.blockRefs = [];
        this.setState({
            firstLoad: true,
            blocks: [...this.state.blocks, new BlockDataType()],
            // blockRefs: [],
        });

        setTimeout(() => {
            // window.scrollTo({top: document.querySelector("#root").scrollHeight - 1000, behavior: 'smooth'});
            let heightSize = document.querySelector(".page-body .page-block:last-child").offsetTop - 190;
            window.scrollTo({top: heightSize, behavior: 'smooth'});
            setTimeout(() => {
                this.setState({
                    firstLoad: false
                });

            }, 1000)
        }, 10)
    };

    collectNewData = () => {
        this.newData.data.blocks = [];

        this.blockRefs && this.blockRefs.forEach((block, i) => {
            if (i === 0) {
                this.newData.data = block.collectBlockNewData();
            } else {
                // if (this.newData.data !== null) {
                !block.state.deleted && this.newData.data.blocks.push(block.collectBlockNewData());
                // }
            }
        });
    };

    collectSections = async (newSection) => {
      await this.setState({
          newFaqSections:[...this.state.newFaqSections, newSection]
      });

        console.log(this.state.newFaqSections);
    };

    pushPageData = (pushType) => {

        this.collectNewData();

        // if (this.newData.data !== null) {
        this.newData.action = pushType;

        //// Push data for save/publish
        instance.put(`pages${this.pageName}`, this.newData, setRequestHeader())
            .then((response) => {
                // console.log(response.data);
                removeFilesFromServer(this.state.filesForDelete);
                SmallPopupMessage('success', `${pushType.charAt(0).toUpperCase() + pushType.slice(1)} Done!`, 2000);
            })
            .catch((error) => {
                // console.log(error);
                SmallPopupMessage('error', error.toString(), false, true);
            });
    };

    render() {
        const {data, blocks, pageTitle, isLoaded, filesForDelete, newFaqSections} = this.state;
        const {pageName} = this;
        let {blockRefs} = this;


        return (
            <div className="page">
                {isLoaded ? (
                        <Fragment>
                            <div className="page-header">
                                <h1 dangerouslySetInnerHTML={{__html: pageTitle}}/>
                                <Buttons pushData={this.pushPageData} settings={['publish', 'save']}/>

                                <button className='fixed-plus' onClick={this.addNewBlock}>
                                    <FontAwesomeIcon icon={['fas', 'plus']}/>
                                </button>
                            </div>

                            <ul className={this.state.firstLoad ? "page-body pageInner with-animation" : "page-body pageInner"}>
                                <PageBlock blockData={data}
                                           blockType={`${pageName.split('/')[1]}-top`}
                                           filesForDelete={filesForDelete}
                                           ref={ref => ref !== null && !blockRefs.includes(ref) && blockRefs.push(ref)}/>
                                {pageName === '/our_team' &&
                                    (blocks && blocks.map((block,i)=>
                                        block.trustees &&
                                        <PageBlock blockData={block}
                                                   key={i}
                                                   blockType={'bouard_of_trustees'}
                                                   ref={ref => ref !== null && !blockRefs.includes(ref) && blockRefs.push(ref)}/>
                                    ))
                                }
                                {blocks && blocks.map((block, i) =>
                                    !block.trustees &&
                                    <PageBlock key={i} blockData={block} blockType={pageName.split('/')[1]}
                                               withDelete={true}
                                               collectNewSection = {this.collectSections}
                                               newSections = {newFaqSections}
                                               filesForDelete={filesForDelete}
                                               ref={ref => ref !== null && !blockRefs.includes(ref) && blockRefs.push(ref)}/>
                                )}
                            </ul>
                        </Fragment>
                    ) :
                    <Loading/>
                }
            </div>
        )
    }
}

export default Page;