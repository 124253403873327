import React, {Component} from 'react';
import {Route, Redirect, withRouter, Switch} from 'react-router-dom';

//Styles
import "./Styles/_reset.scss";
import "./Styles/_normalize.scss";
// import 'bootstrap/dist/css/bootstrap.css'
import './Styles/Bootstrap/bootstrap.min.css'
import './Styles/_global.scss';

import Header from './Components/Header/Header';
import AppRouter from "./AppRouter";
import Menu from "./Components/Menu/Menu";
import Login from './Pages/Login'
import {consolingImage} from './Constants/Constants';
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/ForgotPassword/ResetPassword";

consolingImage();

class App extends Component {
    render() {

        return (
            localStorage.token ? (
                    <>
                        <Header history={this.props.history}/>
                        <div className="page-layout">
                            <Menu/>
                            <div className="main-window-wrapper">
                                {AppRouter}
                            </div>
                        </div>
                    </>
                ) :
                <Switch>
                    <Route exact path="/(login|)" component={Login}/>
                    <Route exact path="/forgot_password" component={ForgotPassword}/>
                    <Route path="/reset_password" component={ResetPassword}/>
                    <Redirect to='/login'/>
                </Switch>
        );
    }
}

export default withRouter(props => <App {...props}/>);