import React, {Component} from 'react';
import {EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './_textEditor.scss';
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from 'draft-js-import-html';

class TextEditor extends Component {
    constructor(props) {
        super(props);

        this.toolbar = {
            options: ['blockType', 'history', 'inline', 'list', 'textAlign', 'link', 'remove'],
            inline: {options: ['bold', 'italic', 'underline']},
            list: {options: ['unordered', 'ordered']},
            blockType: {options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote']},
        };

        this.state = {
            editorState: EditorState.createEmpty(),
            editorNewData: ''
        };
    };


    componentDidMount = () => {
        let newData = this.props.data ? stateFromHTML(this.props.data) : stateFromHTML('');
        let newEditorState = EditorState.createWithContent(newData);
        this.setState({
            editorState: newEditorState,
            editorNewData: this.props.data,
        });

        this.props.editor(this);
    };

    onBlur = () => {
        let newData = stateToHTML(this.state.editorState.getCurrentContent());
        this.setState({editorNewData: newData});
    };

    onEditorStateChange = (editorState) => {
        this.setState({editorState});
    };

    render() {
        const {editorState} = this.state;

        return (
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                toolbar={this.toolbar}
                onBlur={this.onBlur}
            />
        )
    }
}

export default TextEditor;