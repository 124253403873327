import instance, {setRequestHeader} from '../Services/Api';
import PopupMessage, {SmallPopupMessage} from '../Components/Popup/Popup';
import history from "../AppHistory";


const AuthActions = {
    login: (data, needRerender = false) => {
        return instance.post(`/admin/login`, data)
            .then(response => {
                localStorage.setItem('username', response.data.user.email);
                localStorage.setItem('token', response.data.token);
                if (!needRerender) {
                    return history.push(`/home`);
                }
            })
            .catch(error => {
                if (error && error.response) {
                    console.log('error', error.response);
                    SmallPopupMessage('error', error.response.data.message.toString(), false, true);
                }
            });
    },

    logout: () => {
        if (localStorage.token) {
            return instance.post(`/admin/logout`, '', setRequestHeader())
                .then(response => {
                    localStorage.removeItem('username');
                    localStorage.removeItem('token');
                    history.push(`/login`)
                })
                .catch(error => {
                    // console.log('error', error.response.data.message);
                    localStorage.removeItem('username');
                    localStorage.removeItem('token');
                    history.push(`/login`)
                });
        }
    },

    forgotPassword: data => {
        data.url = window.location.host + "/reset_password";
        return (
            instance.post(`/admin/forgot-password`, data)
                .then(() => {
                    PopupMessage('success', 'Success!\nPlese check your E-Mail address', false, true);
                    history.push('/login');
                })
                .catch(() => {
                    SmallPopupMessage('error', 'No account found with that E-mail', false, true);
                })
        )
    },

    resetPassword: (data) => {
        return (
            instance.put(`/admin/reset-password`, data)
                .then(() => {
                    SmallPopupMessage('success', 'Your password changed successfully', 2500, true)
                    history.push('/login');
                })
                .catch(() => {
                    SmallPopupMessage('error', 'Something gone wrong\nTry again', false, true);
                })
        )
    },

    // signup: data => {
    //     return instance.post(`/signup`, data);
    // },
    // register: data => {
    //     const {locale} = localStorage;
    //     data.user.lang = locale;
    //     return instance.post(`/register`, data);
    // },

    // requestPasswordAccess: (data, token) => {
    //     const {locale} = localStorage;
    //     data.token = token;
    //     data.request_password = 1;
    //     data.lang = locale;
    //     return instance.post(`/forgot-password`, data);
    // }
};

export default AuthActions;