import axios from "axios";
import {baseUrl} from "../Constants/Constants";

let instance = axios.create({
    baseURL: baseUrl
});

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export function setRequestHeader() {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.token ? localStorage.token : ''
        }
    };
}

export function fileUpload(file) {

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.token ? localStorage.token : ''
        }
    };

    const formData = new FormData();
    formData.append('file', file);

    return instance.post('file', formData, config);
}

export function fileDelete(fileUrl) {

    return instance.delete('file',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.token ? localStorage.token : ''
            },
            data: {
                "file": fileUrl
            }
        }
    );
}

export default instance;
