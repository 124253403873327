import React, {Component} from 'react';
import {Link} from "react-router-dom";
import './_editbox.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class EditBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            identity: '',
            status: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {id, name} = props.element;

        if (props !== state) {
            return {
                identity: name ? name : id,
                status: props.element.status === 'active'
            };
        }
        return null;
    }

    render() {

        const {slug, ifDelete, element} = this.props;
        const {status, identity} = this.state;

        return (
            <ul className='edit-box-wrapper'>
                <Link to={`${slug}/${identity}`} className='edit-box edit'>
                    <span>Edit</span>
                    <FontAwesomeIcon icon={['fas', 'pen']} className='edit-icon'/>
                </Link>
                <li onClick={() => this.props.toggleStatus(element)} className='edit-box status'>
                    {status ? <span>Show</span> : <span>Hide</span>}
                    <input checked={status} type="checkbox" className="switcher"/>
                </li>
                {ifDelete &&
                <li onClick={() => this.props.deleteElement(identity)} className='edit-box delete'>
                    <span>Delete</span>
                    <FontAwesomeIcon className='edit-icon' icon={['fas', 'trash']}/>
                </li>
                }
            </ul>
        )
    }
}

export default EditBox;
