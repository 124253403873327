import React from "react";
import {Route} from 'react-router-dom';
import {Switch, Redirect} from 'react-router-dom';

import NotFound from './Pages/NotFound';
import ListingPage from './Components/ListingPage/ListingPage';
import ListingInner from './Components/ListingInner/ListingInner';
import Page from './Components/Page/Page';

const AppRouter = (
    <Switch>
        <Route exact path="/(achievements|advantages|life_at_school)" component={ListingPage}/>
        <Route exact path="/(achievements|advantages|events)/:id" component={ListingInner}/>
        <Route exact
               path="/(|home|about_school|student_council|educational_program|admission|competition|contacts|vacancies|faq|our_team)"
               component={Page}/>
        <Route path="/notfound" component={NotFound}/>
        <Redirect from="/login" to="/home"/>
        <Redirect to="/notfound"/>
    </Switch>
);

export default AppRouter;