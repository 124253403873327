import React from 'react';
import './_forgotPassword.scss';
import AuthActions from "../../Actions/AuthActions"

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            confirmPassword: '',
            isMatch: '',
            firstInput: '',
            secondInput: '',
        }
    }

    handleValidate = async (e) => {
        const target = e.target;
        const name = target.name;
        let value = target.value;

        await this.setState({
            [name]: value
        });

        if (name === 'password') {
            this.setState({
                firstInput: this.state.password.includes(' ') || this.state.password === ''
                    ? 'error'
                    : ''
            })
        }

        if (name === 'confirmPassword') {
            this.setState({
                secondInput: this.state.confirmPassword.includes(' ') || this.state.confirmPassword === ''
                    ? 'error'
                    : ''
            })
        }


        if (name === 'confirmPassword') {
            if (this.state.password === this.state.confirmPassword) {
                if (document.querySelector('.popup')) {
                    document.querySelector('.popup').style.animation = 'fadeOutPopup .3s forwards';
                    setTimeout(() => {
                        this.setState({isMatch: ''})
                    }, 300);
                }
            } else {
                this.setState({isMatch: "popup"});
                document.querySelector('.popup').style.animation = 'fadeInPopup .3s forwards';
            }
        }
    };


    handleSubmit = (e) => {
        e.preventDefault();
        let {firstInput, secondInput, isMatch, confirmPassword} = this.state;

        if (firstInput !== 'error' && secondInput !== 'error' && isMatch !== 'popup') {
            let token = window.location.search.slice(7, window.location.search.length);
            let data = {
                token: token,
                password: confirmPassword,
            };
            AuthActions.resetPassword(data);
        }
    };

    render() {
        return (
            <div className="login-page forgot-password">
                <form onSubmit={this.handleSubmit}>
                    <div className='password-image'>
                        <img src='/Images/Icons/forgot-password-icon.png' alt=''/>
                    </div>
                    <h4>Type your new password</h4>
                    <div className="input-wrapper">
                        <div className='logins-wrapper reset-password-inputs'>
                            <div className={this.state.isMatch}/>
                            <input className={this.state.firstInput}
                                   placeholder="Password" type="password"
                                   name="password" required
                                   onChange={this.handleValidate}/>
                            <input className={this.state.secondInput}
                                   placeholder="Confirm Password" type="password"
                                   name="confirmPassword" required
                                   onChange={this.handleValidate}/>
                        </div>
                    </div>
                    <div className="auth-form-actions">
                        <button className="main-button" type="submit">Confirm Password</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ResetPassword;
