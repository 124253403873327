import React, {Component} from 'react';
import AuthActions from '../Actions/AuthActions';
import {Link} from "react-router-dom";
import '../Styles/_loginPage.scss';

class Login extends Component {

    handleSubmit = (e) => {
        e.preventDefault();

        let values = {
            "email": this.refs.email.value,
            "password": this.refs.password.value
        };
        AuthActions.login(values);
    };

    render() {
        return (
            <div className="login-page">
                <form onSubmit={this.handleSubmit}>
                    <img className='logo' src='/Images/header-logo.png' alt="logo"/>
                    <div className="input-wrapper">
                        <div className="logins-wrapper">
                            <input placeholder="E-mail" type="email" id="email" name="email" ref="email"
                                   autoComplete="username" required/>
                            <input placeholder="Password" type="password" id="password" name="password" ref="password"
                                   autoComplete="new-password" required/>
                        </div>
                        <Link to={'/forgot_password'} className="forgot-link">
                            Forgot password?
                        </Link>
                    </div>
                    <div className="auth-form-actions">
                        <button className="main-button" type="submit">Sign In</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;
