import React, {Fragment} from 'react';
import Loading from '../Loading/Loading'

class WorkerBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            worker: {},
            lng: undefined,
            workerIndex: 0,
            isLoaded: false,
            error: false
        };
    }

    static getDerivedStateFromProps(props, state) {

        if (props !== state) {
            return {
                worker: props.worker,
                workerIndex: props.index,
                lng: props.lng
            };
        }
        return null;
    }

    componentDidMount() {
        this.image = new Image();
        this.image.src = this.props.worker.image;
        this.image.onload = this.handleImageLoaded;
        this.image.onerror = this.handleImageError;
    }

    componentWillUnmount() {
        if (this.image) {
            this.image.onload = function () {
            };
            delete this.image;
        }
    }

    handleImageLoaded = () => {
        this.setState({isLoaded: true});
    };

    handleImageError = () => {
        this.setState({
            isLoaded: false,
            error: true
        });
    };

    render() {
        const {workerIndex, worker, lng, isLoaded, error} = this.state;

        return (
            <div className={!error ? 'worker-wrapper' : 'img-wrapper inError'}>
                {(!error) ? (
                    (isLoaded) ? (
                        <div style={{height: '100%'}}>
                            <div className='img' style={{backgroundImage: `url(${worker.image})`}}>
                                <a href={worker.image} target="_blank" rel="noopener noreferrer" download>
                                    <img className='download'
                                         src='/Images/Icons/gallery-download-icon.png' alt='download'/>
                                </a>
                                <img className='delete' onClick={() => this.props.handleDelete(workerIndex)}
                                     src = '/Images/Icons/gallery-delete-icon.png' alt='delete'/>
                            </div>
                            <div className="worker-fields">
                                <div className='field input'>
                                    <span className='field-name'>Name*</span>
                                    <input className='text-input' type='text'
                                           ref={`Name${lng}`}
                                           onBlur={() => this.props.updateWorkersData()}
                                           defaultValue={worker.name ? worker.name[lng] : 'AAA'}
                                           required/>
                                </div>
                                <div className='field input'>
                                    <span className='field-name'>Position*</span>
                                    <input className='text-input' type='text'
                                           ref={`Position${lng}`}
                                           onBlur={() => this.props.updateWorkersData()}
                                           defaultValue={worker.position ? worker.position[lng] : "BBB"}
                                           required/>
                                </div>
                            </div>
                        </div>
                    ) : <Loading/>
                ) : (
                    <Fragment>
                        <p className='error-text'>Error Image not found!</p>
                        <img className='delete' onClick={() => this.props.handleDelete(workerIndex)}
                             src = '/Images/Icons/gallery-delete-icon.png' alt='delete'/>
                    </Fragment>
                )
                }
            </div>
        )
    }
}

export default WorkerBlock;