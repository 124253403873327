import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom";
import instance, {setRequestHeader} from "../../Services/Api";
import Buttons from '../Buttons/Buttons';
import PageBlock from '../PageBlock/PageBlock';
import Loading from '../Loading/Loading'
import {BlockDataType, cutString, removeFilesFromServer} from "../../Constants/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PopupMessage, {SmallPopupMessage} from '../Popup/Popup'
import './_listingInner.scss';

class ListingInner extends Component {

    constructor(props) {
        super(props);

        this.newData = new BlockDataType('-top');
        this.pageName = this.props.location.pathname;

        this.state = {
            data: new BlockDataType('listing'),
            // newData: new BlockDataType('-top'),
            blocks: [],
            blockRefs: [],
            isLoaded: false,
            firstLoad: false,
            filesForDelete: []
        };
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.getPageInnerData();
        } else {
            this.setState({
                isLoaded: true,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            this.getPageInnerData();
        }
    }

    getPageInnerData() {
        this.pageName = this.props.location.pathname.includes('life_at_school') ? this.props.location.pathname.replace('life_at_school', 'events') : this.props.location.pathname;

        this.setState({
            isLoaded: false,
            data: {},
            blocks: [],
            blockRefs: []
        });

        instance.get(this.pageName)
            .then((response) => {
                this.setState({
                    data: response.data,
                    blocks: response.data.blocks,
                    isLoaded: true
                });
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
                PopupMessage('error', error.toString(), false, true);
            });
    }

    collectPageInnerNewData = () => {
        if (this.newData) {
            this.newData.blocks = [];
        }
        this.state.blockRefs && this.state.blockRefs.forEach((block, i) => {
            if (i === 0) {
                this.newData = block.collectBlockNewData();
            } else {
                !block.state.deleted && this.newData.blocks.push(block.collectBlockNewData());
            }
        });

        console.log(this.newData)
    };

    addNewBlock = () => {
        this.setState({
            firstLoad: true,
            blocks: [...this.state.blocks, new BlockDataType('advantages')],
            blockRefs: []
        });

        setTimeout(() => {
            window.scrollTo({top: document.querySelector("#root").scrollHeight, behavior: 'smooth'});
            setTimeout(() => {
                this.setState({
                    firstLoad: false
                });
            }, 1000)
        }, 10)
    };

    pushPageInnerData = () => {

        this.collectPageInnerNewData();

        //// Push data for save/publish
        instance.put(this.pageName, this.newData, setRequestHeader())
            .then((response) => {
                removeFilesFromServer(this.state.filesForDelete);
                SmallPopupMessage('success', 'Publish Done!', 2000);
            })
            .catch((error) => {
                // console.log(error);
                SmallPopupMessage('error', error.toString(), false, true);
            });
    };

    render() {
        const {data, blocks, isLoaded, filesForDelete} = this.state;
        const {pageName} = this;
        let {blockRefs} = this.state;

        return (
            <div className="page">
                {isLoaded ? (
                        <Fragment>
                            <div className="page-header">
                                <h1>
                                    <Link to={pageName.includes('event') ? '/life_at_school' : `/${pageName.split('/')[1]}`}
                                          dangerouslySetInnerHTML={{__html: pageName.split('/')[1]}}/>
                                    <span> > {data.title.hy ? cutString(data.title.hy, 35) : '  _ _ _'}</span>
                                </h1>

                                <Buttons pushData={this.pushPageInnerData} settings={['publish']}/>

                                {(pageName.includes('advantages')) &&
                                <button className='fixed-plus' onClick={this.addNewBlock}>
                                    <FontAwesomeIcon icon={['fas', 'plus']}/>
                                </button>
                                }
                            </div>

                            <ul className={this.state.firstLoad ? "page-body pageInner with-animation" : "page-body pageInner"}>
                                <PageBlock blockData={data}
                                           blockType={`${pageName.split('/')[1]}-top`}
                                           filesForDelete={filesForDelete}
                                           ref={ref => ref !== null && !blockRefs.includes(ref) && blockRefs.push(ref)}/>
                                {blocks && blocks.map((block, i) =>
                                    <PageBlock key={i} blockData={block} blockType={pageName.split('/')[1]}
                                               withDelete={true}
                                               filesForDelete={filesForDelete}
                                               ref={ref => ref !== null && !blockRefs.includes(ref) && blockRefs.push(ref)}/>
                                )}
                            </ul>
                        </Fragment>
                    ) :
                    <Loading/>
                }
            </div>
        )
    }
}

export default ListingInner;