import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from "react-router-dom";
import history from './AppHistory';
import App from './App';
import Favicon from 'react-favicon';

ReactDOM.render(
    <Router history={history}>
        <Favicon url="./Images/favicon.png" />
        <App/>
    </Router>,
    document.getElementById('root')
);