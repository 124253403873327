import React from 'react';
import './_trustees.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Trustees extends React.Component {

    collectData = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const {index, lng, collectTrustees} = this.props;

        const object = {
            name: name,
            value: value
        };

        collectTrustees(object, index, lng);
    };

    removeTrustees = () => {
        const {removeTrustees, index} = this.props;
        removeTrustees(index);
    };

    render() {
        const {trustees, lng} = this.props;

        return (
            <div className='trustees-content'>
                <div className='trustees-content-delete' onClick={this.removeTrustees}>
                    <FontAwesomeIcon icon={['fas', 'plus']}/>
                </div>
                <div className='field input'>
                    <span className='field-name'>Full Name*</span>
                    <input name='fullName' className='text-input' type='text'
                           value={trustees.fullName[lng] ? trustees.fullName[lng] : ''}
                           required
                           onChange={this.collectData}
                    />
                </div>
                <div className='field input'>
                    <span className='field-name'>Position*</span>
                    <input name='position' className='text-input' type='text'
                           value={trustees.position[lng] ? trustees.position[lng] : ''}
                           required
                           onChange={this.collectData}
                    />
                </div>
            </div>
        )
    }
}

export default Trustees;