import React, {Component} from 'react';
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import instance, {setRequestHeader} from '../../Services/Api';
import PageBlock from '../PageBlock/PageBlock';
import EditBox from "../EditBox/EditBox";
import Loading from '../Loading/Loading'
import Buttons from "../Buttons/Buttons";
import PopupMessage, {ConfirmationPopupMessage,SmallPopupMessage} from '../Popup/Popup'
import {BlockDataType, cutString} from "../../Constants/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from 'react-router-dom';
import './_listingPage.scss';

class ListingPage extends Component {

    constructor(props) {
        super(props);

        this.pageName = this.props.location.pathname.includes('life_at_school') ? this.props.location.pathname.replace('life_at_school', 'events') : this.props.location.pathname;
        this.blockRef = undefined;
        this.newData = {
            data: {}
        };

        this.state = {
            data: {},
            dataList: [],
            isLoaded: false,
        }
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            this.getData();
        }
    }

    getData = () => {
        this.blockRef = undefined;
        this.pageName = this.props.location.pathname.includes('life_at_school') ? this.props.location.pathname.replace('life_at_school', 'events') : this.props.location.pathname;

        this.setState({
            isLoaded: false,
            data: {},
            dataList: [],
            pageTitle: ''
        });

        this.getPageData();
        this.getPageListing();
    };

    getPageData() {

        instance.get(`pages${this.props.location.pathname}`)
            .then((response) => {
                this.setState({
                    pageTitle: response.data.title,
                    data: response.data.save_data ? response.data.save_data : response.data.data
                });
            })
            .catch((error) => {
                console.log(error);
                PopupMessage('error', error.toString(), false, true);
            });
    }

    async getPageListing() {
        let url = this.searchURL();

        await instance.get(url)
            .then((response) => {
                if (response.data.data) {
                    console.log(response.data);
                    this.setState({
                        dataList: response.data.data,
                        isLoaded: true
                    });
                } else {
                    this.setState({
                        dataList: response.data,
                        isLoaded: true
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                PopupMessage('error', error.toString(), false, true);
            });


        url === '/advantages?' &&
            this.state.dataList.map((e,i) => {
               let myUrl = 'advantages/' + e.name;
                return instance.get(myUrl)
                    .then((response) => {
                        let obj = this.state.dataList;
                        obj[i].image = response.data.image;
                        this.setState({
                            dataList:obj
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        PopupMessage('error', error.toString(), false, true);
                    });
            });
    }

    searchURL = () => {
        let searchWord = this.refs.searchInput ? this.refs.searchInput.value : undefined;
        let searchFrom = this.refs.fromInput ? this.refs.fromInput.value : undefined;
        let searchTo = this.refs.toInput ? this.refs.toInput.value : undefined;

        let searchUrl = `${this.pageName}?`;

        if (searchWord) {
            searchUrl += `&search=${searchWord}`;
        }

        if (searchFrom) {
            searchUrl += `&from=${searchFrom}`;
        }

        if (searchTo) {
            searchUrl += `&to=${searchTo}`;
        }

        return searchUrl;
    };

    toggleStatus = (element) => {

        let statusData = {};
        if (element.status === 'active') {
            statusData = {
                status: "inactive"
            }
        } else {
            statusData = {
                status: "active"
            };
        }

        instance.put(`${this.pageName}/${element.name ? element.name : element.id}`, statusData, setRequestHeader())
            .then((response) => {

                let index = this.state.dataList.findIndex(elem => elem === element);
                let newDataList = Object.assign([], this.state.dataList, {[index]: response.data});

                this.setState({
                    dataList: newDataList
                });

                // console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
                PopupMessage('error', error.toString(), false, true);
            });
    };

    addNewElement = () => {

        let newElement = {};
        if (this.pageName.includes('events')) {
            newElement = new BlockDataType('events-top');

            newElement.highlight.hy = "";

            let dateObj = new Date();
            let year = dateObj.getUTCFullYear();
            let month = ("0" + (dateObj.getUTCMonth() + 1)).slice(-2);
            let day = ("0" + dateObj.getUTCDate()).slice(-2);
            newElement.date = year + "-" + month + "-" + day;
        } else {
            newElement = new BlockDataType('achievements-top');
        }

        newElement.title.hy = "";
        newElement.desc.hy = "";

        instance.post(`${this.pageName}`, newElement, setRequestHeader())
            .then((response) => {
                SmallPopupMessage('success', 'Created!', 1500);
                let url = `${this.pageName}/${response.data.id}`;
                this.props.history.push(url);
            })
            .catch((error) => {
                console.log(error);
                SmallPopupMessage('error', error.toString(), false, true);
            });
    };

    deleteElement = (identity) => {
        const deleting = () => {
            instance.delete(`${this.pageName}/${identity}`, setRequestHeader())
                .then((response) => {

                    let obj = this.state.dataList;
                    let newObj = obj.filter(data => {
                        return data.id !== identity;
                    });

                    this.setState({
                        dataList: newObj,
                    });

                    // this.getPageListing();
                    SmallPopupMessage('success', 'Deleted!', 1500);
                })
                .catch((error) => {
                    console.log(error);
                    SmallPopupMessage('error', error.toString(), false, true);
                });
        };
        ConfirmationPopupMessage(deleting, 'Delete');
    };

    pushPageData = (pushType) => {
        //Collect all data

        this.newData.data = this.blockRef.collectBlockNewData();
        this.newData.action = pushType;
        console.log(this.newData);

        ////Push data for save/publish
        instance.put(`pages${this.props.location.pathname}`, this.newData, setRequestHeader())
            .then((response) => {
                // console.log(response.data);
                SmallPopupMessage('success', `${pushType.charAt(0).toUpperCase() + pushType.slice(1)} Done!`, 2000);
            })
            .catch((error) => {
                // console.log(error);
                SmallPopupMessage('error', error.toString(), false, true);
            });
    };

    render() {
        const {data, dataList, isLoaded, pageTitle} = this.state;
        const {pageName} = this;

        return (
            (isLoaded) ? (
                <div className="page">
                    <div className="page-header">
                        <h1 dangerouslySetInnerHTML={{__html: pageTitle}}/>
                        <Buttons pushData={this.pushPageData} settings={['save', 'publish']}/>

                        {!pageName.includes('advantages') &&
                        <button className='fixed-plus' onClick={this.addNewElement}>
                            <FontAwesomeIcon icon={['fas', 'plus']}/>
                        </button>
                        }
                    </div>

                    <ul className="page-body">
                        <PageBlock blockData={data} blockType={'listing'}
                                   ref={ref => (this.blockRef = ref)}/>
                        {/*{dataList.length > 0 &&*/}
                        <li className="page-block">
                            {pageName === "/events" &&
                            <div className='search'>
                                <div className='search-input'>
                                    <input type='text' className='search-input-text' placeholder='Search'
                                           ref='searchInput'
                                           onKeyDown={(e) => e.key === 'Enter' && this.getPageListing()}/>
                                    <FontAwesomeIcon icon={['fas', 'search']} className='search-input-icon'/>
                                </div>
                                <div className='search-date'>
                                    <div className='from-block'>
                                        <span>From</span>
                                        <input className='search-date-input' type='date' ref='fromInput'/>
                                    </div>
                                    <div className='to-block'>
                                        <span>To</span>
                                        <input className='search-date-input' type='date' ref='toInput'/>
                                    </div>
                                </div>
                                <button className='main-button search-button'
                                        onClick={() => this.getPageListing()}>Search
                                </button>
                            </div>
                            }
                            <ul className="element-collection">
                                {dataList.length > 0 ?
                                    dataList.map((element, i) =>
                                        <li className='element' key={i}>

                                            {pageName.includes('advantages')
                                                ?
                                                    element.image ?
                                                        <div className='element-img'
                                                             style={{backgroundImage: `url(${element.image})`}}/>
                                                        :
                                                        <div className='element-img noImg' />
                                                :
                                                    element.gallery && element.gallery[0] ?
                                                        <div className='element-img'
                                                             style={{backgroundImage: `url(${element.gallery[0]})`}}/>
                                                        :
                                                        <div className='element-img noImg'/>
                                            }

                                            <div className='element-text'>
                                                <span
                                                    dangerouslySetInnerHTML={{__html: cutString(element.title.hy, 20)}}/>
                                                {/*<span dangerouslySetInnerHTML={{__html: element.name}}/>*/}
                                                <UncontrolledDropdown tag="div">
                                                    <DropdownToggle className='edit-button'>
                                                        <img className='edit-button-img'
                                                             src='/Images/Icons/edit_menu_icon.png' alt=''/>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <EditBox slug={pageName}
                                                                 element={element}
                                                                 ifDelete={!pageName.includes('advantages')}
                                                                 deleteElement={this.deleteElement}
                                                                 toggleStatus={this.toggleStatus}/>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </li>)
                                    :
                                    <div className='no-search-result'>
                                        <p>
                                            Sorry but nothing matched your search terms.
                                        </p>
                                    </div>
                                }
                            </ul>
                        </li>
                    </ul>
                </div>) : <Loading/>
        )
    }
}

export default withRouter(ListingPage);