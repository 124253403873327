import React, {Fragment} from 'react';
import GalleryBlock from '../GalleryBlock/GalleryBlock';
import {fileUpload} from "../../Services/Api";
import {Nav, NavItem, TabPane, NavLink, TabContent} from "reactstrap";
import {BlockDataType, cleanData, languages} from "../../Constants/Constants";
import PopupMessage, {ConfirmationPopupMessage} from '../Popup/Popup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './_pageBlock.scss';
import TextEditor from "../TextEditor/TextEditor";
import Trustees from "../Trustees/Trustees";
import Faq from "../FAQ/Faq";

class PageBlock extends React.Component {

    constructor(props) {
        super(props);

        this.titleInputs = [];
        this.questionInputs = [];
        this.nameInputs = [];
        this.positionInputs = [];
        this.dateInputs = [];
        this.ifEmpty = false;

        this.state = {
            activeTab: '1',
            blockData: {},
            blockDataType: {},
            textEditors: [],
            gallery: [],
            workers: [],
            iconSrc: undefined,
            deleted: false,
            featured: 0,
            status: 'active',
            trustees: [],
            image: '',

            // ifEmpty: false,
        };
    }

    componentDidMount() {
        const {blockData, blockType} = this.props;

        this.setState({
            blockData: blockData,
            gallery: blockData.gallery,
            workers: blockData.workers,
            iconSrc: blockData.icon,
            status: blockData.status,
            trustees: blockData.trustees,
            featured: blockData.featured,
            image: blockData.image,
            blockDataType: BlockDataType(blockType),
        });

    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    toggleFeatured = () => {
        this.setState({
            featured: this.state.featured === 1 ? 0 : 1
        })
    };

    toggleStatus = () => {
        this.setState({
            status: this.state.status === 'active' ? 'inactive' : 'active',
        });
        console.log(this.state.status);
    };

    deleteBlock = () => {
        this.setState({
            deleted: true
        });
    };

    handleIconUpload = (e) => {

        let file = e.target.files[0];

        if (file) {

            if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/svg+xml') {
                PopupMessage('error', "JPG, PNG or SVG are valid.", false, true);
                return false;
            }

            if (file.size > 100000) {
                PopupMessage('error', 'Max. 100kb', false, true);
                return false;
            }

            fileUpload(file)
                .then(response => {
                    this.setState({iconSrc: response.data.file});
                })
                .catch(error => {
                    console.log(error);
                    PopupMessage('error', error.toString(), false, true);
                });
        }
    };

    handleImgUpload = (files) => {

        let newGallery = this.state.gallery ? [...this.state.gallery] : [];

        Array.from(files).forEach(file => {
            fileUpload(file)
                .then(response => {
                    newGallery.push(response.data.file);
                    this.setState({
                        gallery: newGallery
                    });
                })
                .catch(error => {
                    PopupMessage('error', error.toString(), false, true);
                });
        })
    };

    handleImageUpload = (image) => {
        fileUpload(image[0])
            .then(response => {
                let prevImg = this.state.image ? this.state.image : '';
                let finalImage = response.data.file ? response.data.file : prevImg;
                this.setState({
                    image: finalImage
                });
            })
            .catch(error => {
                PopupMessage('error', error.toString(), false, true);
            });
    };

    handleWorkerUpload = (files) => {

        let workers = this.state.workers ? [...this.state.workers] : [];

        Array.from(files).forEach(file => {
            fileUpload(file)
                .then(response => {
                    let worker = {
                        name: {},
                        position: {},
                        image: response.data.file
                    };
                    workers.push(worker);
                    this.setState({
                        workers: workers
                    });
                })
                .catch(error => {
                    PopupMessage('error', error.toString(), false, true);
                });
        })
    };

    handleImgDelete = (file) => {
        if (this.state.gallery) {
            this.props.filesForDelete.push(file);
            this.setState({
                gallery: this.state.gallery.filter(img => img !== file)
            });
        }
    };

    handleImageDelete = (file) => {
        let image = this.state.image;
        if (image) {
            this.props.filesForDelete.push(file);
            this.setState({
                image: ''
            })
        }
    };

    handleWorkerDelete = (worker) => {
        if (this.state.workers) {
            this.props.filesForDelete.push(worker.image);
            this.setState({
                workers: this.state.workers.filter(a => a.image !== worker.image)
            });
        }
    };

    // validation = (searchItem, searchInput, i, blockDataType) => {
    //
    //     if (searchItem in blockDataType) {
    //         if (searchInput[i].value === '') {
    //             searchInput[i].classList.add('empty');
    //             console.log('some of ' +searchItem.toUpperCase()+ ' is empty');
    //             return false;
    //         }
    //         else {
    //             searchInput[i].classList.remove('empty');
    //         }
    //     }
    // };

    collectBlockNewData = () => {
        let {blockDataType} = this.state;

        for (let i = 0; i < languages.length; i++) {

            // this.validation('title', this.titleInputs, i, blockDataType);
            // this.validation('question', this.questionInputs, i, blockDataType);
            // this.validation('name', this.nameInputs, i, blockDataType);
            // this.validation('position', this.positionInputs, i, blockDataType);

            if ('title' in blockDataType) {
                if (this.titleInputs[i].value !== '') {
                    blockDataType.title[languages[i].lngKey] = this.titleInputs[i].value;
                }
            }

            if ('desc' in blockDataType) {
                if (this.state.textEditors[i].state.editorNewData === '<p><br></p>') {
                    blockDataType.desc = {};
                } else if (this.state.textEditors[i].state.editorNewData !== '') {
                    blockDataType.desc[languages[i].lngKey] = this.state.textEditors[i].state.editorNewData;
                }
            }

            if ('question' in blockDataType) {
                if (this.questionInputs[i].value !== '') {
                    blockDataType.question[languages[i].lngKey] = this.questionInputs[i].value;
                }
            }

            if ('answer' in blockDataType) {
                if (this.state.textEditors[i].state.editorNewData === '<p><br></p>') {
                    blockDataType.answer = {};
                } else if (this.state.textEditors[i].state.editorNewData !== '') {
                    blockDataType.answer[languages[i].lngKey] = this.state.textEditors[i].state.editorNewData;
                }
            }

            if ('name' in blockDataType) {
                if (this.nameInputs[i].value !== '') {
                    blockDataType.name[languages[i].lngKey] = this.nameInputs[i].value;
                }
            }

            if ('position' in blockDataType) {
                if (this.positionInputs[i].value !== '') {
                    blockDataType.position[languages[i].lngKey] = this.positionInputs[i].value;
                }
            }
        }

        // this.validation('date', this.dateInputs, 0, blockDataType);
        // this.validation('faqSection', this.faqSectionInputs, 0, blockDataType);

        if ('trustees' in blockDataType) {
            blockDataType.trustees = this.state.trustees;
        }

        if ('date' in blockDataType) {
            blockDataType.date = this.dateInputs[0].value;
        }

        if ('workers' in blockDataType) {
            blockDataType.workers = this.state.workers;
        }

        if ('faqSection' in blockDataType) {
            // blockDataType.faqSection = this.state.blockData.faqSection;

        }

        if ('status' in blockDataType) {
            blockDataType.status = this.state.status;
        }

        if ('gallery' in blockDataType) {
            blockDataType.gallery = this.state.gallery;
        }

        if ('image' in blockDataType) {
            blockDataType.image = this.state.image;
        }

        if ('icon' in blockDataType) {
            blockDataType.icon = this.state.iconSrc;
        }

        if ('featured' in blockDataType) {
            blockDataType.featured = this.state.featured;
        }

        return cleanData(blockDataType);
    };

    addTrustees = () => {

        let newObject = {
            fullName: {},
            position: {}
        };

        languages.map((language) => {
            return newObject = {
                fullName: {...newObject.fullName, [language.lngKey]: ''},
                position: {...newObject.position, [language.lngKey]: ''}
            };
        });

        const {trustees} = this.state;

        trustees.push(newObject);

        this.setState({
            trustees: trustees
        });
    };

    removeTrustees = (index) => {
        const {trustees} = this.state;

        trustees.splice(index, 1);

        this.setState({
            trustees: trustees
        })
    };

    collectTrustees = (trusteesInputs, index, lng) => {

        const name = trusteesInputs.name;
        const value = trusteesInputs.value;

        const {trustees} = this.state;
        trustees[index][name][lng] = value ? value : '';

        this.setState({
            trustees:trustees
        });
    };

    collectSection = (newSection) => {
        const {collectNewSection} = this.props;

        collectNewSection(newSection);
    };

    // validator = () => {
    //     for(let i = 0; i < languages.length; i++) {
    //         if (this.titleInputs[i].value === '') {
    //             this.titleInputs[i].classList.add('empty');
    //         } else {
    //             this.titleInputs[i].classList.remove('empty');
    //         }
    //     }
    // };

    render() {
        let {titleInputs, questionInputs, nameInputs, positionInputs, dateInputs} = this;
        const {blockData, blockDataType, gallery, workers, iconSrc,
               status, deleted, image, textEditors, featured, trustees} = this.state;
        // let {textEditors, featured, trustees} = this.state;
        let {blockType, withDelete, newSections} = this.props;
        console.log(blockData);


        return (
            blockType !== 'faq-top' &&
            <Fragment>
                {blockData && !deleted &&
                <li className='page-block'>
                    <Nav tabs>
                        {languages.map((language, i) => (

                            <NavItem key={i}>
                                <NavLink
                                    className={this.state.activeTab === (i = (i + 1).toString()) ? 'active' : ''}
                                    onClick={() => {
                                        this.toggle(i)
                                    }}>
                                    {language.lngName}
                                </NavLink>
                            </NavItem>
                        ))}
                        {withDelete && blockDataType !== 'bouard_of_trustees' &&
                        <button className='delete-block-button'
                                onClick={() => ConfirmationPopupMessage(this.deleteBlock, 'Delete')}>
                            <span>DELETE</span>
                            <FontAwesomeIcon icon={['fas', 'window-close']}/>
                        </button>
                        }
                    </Nav>
                    <div className='tabs-body'>
                        <TabContent activeTab={this.state.activeTab}>
                            {languages.map((language, i) =>
                                <TabPane key={i} tabId={(i + 1).toString()}>

                                    <div className='page-block-content'>
                                        <Fragment>
                                            <div className='content-upload-section'>
                                                {'title' in blockDataType &&
                                                <div className='field input'>
                                                    <span className='field-name'>Title*</span>
                                                    <input key={`${blockData.title}${language.lngKey}`}
                                                           className='text-input' type='text'
                                                           defaultValue={blockData.title && blockData.title[language.lngKey]}
                                                           ref={e => e !== null && !titleInputs.includes(e) && titleInputs.push(e)}
                                                           required/>
                                                </div>
                                                }
                                                {'date' in blockDataType && language.lngKey === 'hy' &&
                                                <div className='field input'>
                                                    <span className='field-name'>Date*</span>
                                                    <input className='text-input' type='date'
                                                           defaultValue={blockData.date}
                                                           ref={e => e !== null && !dateInputs.includes(e) && dateInputs.push(e)}/>
                                                </div>
                                                }
                                                {'featured' in blockDataType && language.lngKey === 'hy' &&
                                                <div className='featured-block'>
                                                    <label className="checkbox-button" onChange={this.toggleFeatured}>
                                                        <span>Featured</span>
                                                        <input defaultChecked={featured} className="inp-cbx" id="cbx"
                                                               type="checkbox"/>
                                                        <label className="cbx" htmlFor="cbx">
                                                            <span>
                                                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                                                    <polyline points="1.5 6 4.5 9 10.5 1"/>
                                                                </svg>
                                                            </span>
                                                        </label>
                                                    </label>
                                                </div>
                                                }
                                                {'name' in blockDataType &&
                                                <div className='field input'>
                                                    <span className='field-name'>Full Name*</span>
                                                    <input key={`${blockData.name}${language.lngKey}`}
                                                           className='text-input' type='text'
                                                           defaultValue={blockData.name && blockData.name[language.lngKey]}
                                                           ref={e => e !== null && !nameInputs.includes(e) && nameInputs.push(e)}
                                                           required/>
                                                </div>
                                                }
                                                {'position' in blockDataType &&
                                                <div className='field input'>
                                                    <span className='field-name'>Position*</span>
                                                    <input key={`${blockData.position}${language.lngKey}`}
                                                           className='text-input' type='text'
                                                           defaultValue={blockData.position && blockData.position[language.lngKey]}
                                                           ref={e => e !== null && !positionInputs.includes(e) && positionInputs.push(e)}
                                                           required/>
                                                </div>
                                                }
                                                {'faqSection' in blockDataType &&
                                                    <Faq collectSection={this.collectSection}
                                                         sections = {newSections}
                                                         lng = {language.lngKey}/>
                                                }
                                                {'question' in blockDataType &&
                                                <div className='field input question'>
                                                    <span className='field-name'>Question*</span>
                                                    <input key={`${blockData.question}${language.lngKey}`}
                                                           ref={e => e !== null && !questionInputs.includes(e) && questionInputs.push(e)}
                                                           className='text-input' type='text'
                                                           defaultValue={blockData.question && blockData.question[language.lngKey]}
                                                           required/>
                                                </div>
                                                }
                                                {'icon' in blockDataType &&
                                                <Fragment>
                                                    <label onChange={this.handleIconUpload}
                                                           className='field file-upload icon'>
                                                        <span className='field-name'>Icon*</span>
                                                        <span className='main-button'>Upload</span>
                                                        <input type='file' hidden/>
                                                    </label>
                                                    {iconSrc ? (
                                                        <img className="icon-preview" src={iconSrc}
                                                             alt='icon'/>
                                                    ) : (
                                                        <div className="no-icon"><FontAwesomeIcon
                                                            icon={['fas', 'file']}/></div>
                                                    )}
                                                </Fragment>
                                                }
                                                {'status' in blockDataType && language.lngKey === 'hy' &&
                                                <label className='edit-box status' onChange={this.toggleStatus}>
                                                    {status === 'active' ? <span>Show</span> : <span>Hide</span>}
                                                    <input defaultChecked={status === 'active' ? 1 : 0} type="checkbox"
                                                           ref={`${blockType}Status`}
                                                           className="switcher"/>
                                                </label>
                                                }
                                            </div>
                                            {'gallery' in blockDataType &&
                                            <GalleryBlock gallery={gallery && gallery}
                                                          handleUpload={this.handleImgUpload}
                                                          handleDelete={this.handleImgDelete}/>
                                            }
                                            {'workers' in blockDataType &&
                                            <GalleryBlock
                                                key={`${workers}${language.lngKey}`}
                                                lng={[language.lngKey]}
                                                workers={workers && workers}
                                                handleUpload={this.handleWorkerUpload}
                                                handleDelete={this.handleWorkerDelete}
                                            />
                                            }
                                            {'image' in blockDataType &&
                                            <GalleryBlock image={image && image}
                                                          handleUpload={this.handleImageUpload}
                                                          handleDelete={this.handleImageDelete}
                                                          multiple={false}
                                            />
                                            }
                                            {'desc' in blockDataType &&
                                            <div className={'image' in blockDataType && 'optional-content'}>
                                                <h5>Description</h5>
                                                <TextEditor id={i} key={i} editor={e => (textEditors.push(e))}
                                                            data={blockData.desc && blockData.desc[language.lngKey]}/>
                                            </div>
                                            }
                                            {'answer' in blockDataType &&
                                            <Fragment>
                                                <h5>Answer</h5>
                                                <TextEditor id={i} key={i} editor={e => (textEditors.push(e))}
                                                            data={blockData.answer && blockData.answer[language.lngKey]}/>
                                            </Fragment>
                                            }
                                            {'trustees' in blockDataType &&
                                            <div className='trustees'>
                                                <button className='add-trustees' onClick={this.addTrustees}>
                                                    <FontAwesomeIcon icon={['fas', 'plus']}/>
                                                </button>
                                                <div className='trustees-block'>
                                                    {trustees &&
                                                    trustees.map((data, i) =>
                                                        <Trustees key={i} index={i}
                                                                  lng={[language.lngKey]}
                                                                  trustees={data}
                                                                  removeTrustees={this.removeTrustees}
                                                                  collectTrustees={this.collectTrustees}
                                                        />
                                                    )
                                                    }
                                                </div>
                                            </div>
                                            }
                                        </Fragment>
                                    </div>
                                </TabPane>
                            )}
                        </TabContent>
                    </div>
                </li>
                }
            </Fragment>
        )
    }
}

export default PageBlock;