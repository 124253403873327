import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import instance from '../../Services/Api';
import {UncontrolledCollapse} from 'reactstrap';
import './_menu.scss';

class Menu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuData: []
        };
    }

    componentDidMount() {
        this.getMenus();
    }

    getMenus() {
        instance.get('/pages')
            .then((response) => {
                this.setState({
                    menuData: response.data
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    subMenuIcon = (e) => {
        let element = e.currentTarget;
        element.classList.toggle('menu-item-rotate');
    };

    filterByGroup(groupName) {
        const {menuData} = this.state;
        let subMenus = [];
        menuData.forEach((menu, i) => {
            if (menu.group === groupName) {
                subMenus.push(
                    <li key={i}>
                        <NavLink activeClassName="active-like" className='menu-item' to={`/${menu.slug}`}>
                            <span className="menu-subList-text" dangerouslySetInnerHTML={{__html: menu.title}}/>
                        </NavLink>
                    </li>
                )
            }
        });
        return subMenus;
    }

    render() {
        const {menuData} = this.state;
        let groupName = null;

        return (
            <div className='main-menu-wrapper'>
                <ul className='main-menu'>
                    {menuData && menuData.map((menu, i) =>

                        (menu.group === null) ? (
                            <li key={i}>
                                <NavLink activeClassName="active-like" className='menu-item' to={`/${menu.slug}`}>
                                    <div className="menu-item-icon"
                                         style={{backgroundImage: `url(${menu.icon})`}}/>
                                    <span className="menu-item-text"
                                       dangerouslySetInnerHTML={{__html: menu.title}}/>
                                </NavLink>
                            </li>
                        ) : (
                            menu.group !== groupName && (
                                <li className="menu-item-collapsible" key={i}>
                                    <div onClick={this.subMenuIcon} className='menu-item title' id={'menu-' + i}>
                                        <div className="menu-item-icon"
                                             style={{backgroundImage: `url(${menu.icon})`}}/>
                                        <span className="menu-item-text"
                                              dangerouslySetInnerHTML={{__html: groupName = menu.group}}/>
                                    </div>
                                    <UncontrolledCollapse toggler={'menu-' + i}>
                                        <ul className="menu-subList">
                                            {this.filterByGroup(menu.group)}
                                        </ul>
                                    </UncontrolledCollapse>
                                </li>
                            )
                        )
                    )}
                </ul>
            </div>
        )
    }
}

export default Menu;