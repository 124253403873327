import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import './_popup.scss'
import AuthActions from "../../Actions/AuthActions";

const PopupMessage = (type, message, timer = false, button = false) => {

    let buttonColor = '';

    if (message.includes('401')) {
        return LoginPopupMessage();
    }

    if (message.includes('The given data was invalid.')) {
        return LoginPopupMessage();
    }

    if (type === 'success') {
        buttonColor = '#8015B7';
    } else if (type === 'error') {
        buttonColor = '#f27474';
    } else {
        buttonColor = '#f8bb86';
    }

    Swal.fire({
        position: 'center',
        type: type,
        title: message,
        showConfirmButton: button,
        timer: timer,
        confirmButtonColor: buttonColor,
    });
};

const SmallPopupMessage = (type, message, timer = 2500, button = false) => {
    if (message.includes('401')) {
        return LoginPopupMessage();
    }

    if (message.includes('The given data was invalid.')) {
        return LoginPopupMessage();
    }

    let buttonColor = '';

    if (type === 'success') {
        buttonColor = '#8015B7';
    } else if (type === 'error') {
        buttonColor = '#f27474';
    } else {
        buttonColor = '#f8bb86';
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: button,
        confirmButtonColor: buttonColor,
        timer: timer
    });

    Toast.fire({
        type: type,
        title: message
    });
};

const ConfirmationPopupMessage = (successFunction, buttonText = "Yes", message = "Are you sure?") => {

    Swal.fire({
        title: message,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#8015B7',
        cancelButtonColor: '#ffa30c',
        confirmButtonText: buttonText,
    }).then((result) => {
        return result.value ? successFunction() : false;
    })
};

const LoginPopupMessage = () => {
    Swal.fire({
        title: 'Session expired.\nPlease re-login for continue!',
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Sign In',
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#8015B7',
        cancelButtonColor: '#FFCB05',
        customClass: {
            container: 'login-container',
            popup: 'login-popup',
            title: 'login-title',
            confirmButton: 'login-confirm-button',
            cancelButton: 'login-cancel-button'
        },
        html:
            '<input id="myInput1" type="email" class="swal2-input login-input" placeholder="E-Mail"/>' +
            '<input id="myInput2" type="password" class="swal2-input login-input" placeholder="Password" autocomplete="new-password"/>',
        preConfirm: () => {
            return [
                document.getElementById('myInput1').value,
                document.getElementById('myInput2').value
            ]
        },
    }).then(result => {
        if (result.value) {
            let values = {
                "email": result.value[0],
                "password": result.value[1]
            };
            AuthActions.login(values, true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            AuthActions.logout();
        }
    });
};

export default PopupMessage;
export {ConfirmationPopupMessage, SmallPopupMessage};