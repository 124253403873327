import React, {Component, Fragment} from 'react';
import Loading from '../Loading/Loading'

class ImageBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            source: '',
            imgIndex: 0,
            isLoaded: false,
            error: false
        };
    }

    static getDerivedStateFromProps(props, state) {

        if (props !== state) {
            return {
                source: props.source,
                imgIndex: props.index
            };
        }
        return null;
    }

    componentDidMount() {
        this.image = new Image();
        this.image.src = this.props.source;
        this.image.onload = this.handleImageLoaded;
        this.image.onerror = this.handleImageError;
    }

    componentWillUnmount() {
        if (this.image) {
            this.image.onload = function () {
            };
            delete this.image;
        }
    }

    handleImageLoaded = () => {
        this.setState({isLoaded: true});
    };

    handleImageError = () => {
        this.setState({
            isLoaded: false,
            error: true
        });
    };

    render() {

        const {source, isLoaded, error, imgIndex} = this.state;

        return (
            <div className={!error ? 'img-wrapper' : 'img-wrapper inError'}>
                {(!error) ? (
                        isLoaded ? (
                            <div className='img'>
                                <img className='main-image' src={source} alt=''/>
                                <a href={source} target="_blank" rel="noopener noreferrer" download>
                                    <img className='download'
                                         src='/Images/Icons/gallery-download-icon.png' alt='download'/>
                                </a>
                                <img className='delete' onClick={() => this.props.handleDelete(imgIndex)}
                                     src='/Images/Icons/gallery-delete-icon.png' alt='delete'/>
                            </div>
                        ) : <Loading/>
                    )
                    : (
                        <Fragment>
                            <p className='error-text'>Error Image not found!</p>
                            <img className='delete' onClick={() => this.props.handleDelete(imgIndex)}
                                 src = '/Images/Icons/gallery-delete-icon.png' alt='delete'/>
                        </Fragment>
                    )
                }
            </div>
        )
    }
}

export default ImageBlock;