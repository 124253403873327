import React from 'react';
import './_buttons.scss'

class Buttons extends React.Component {

    render() {

        const {settings} = this.props;

        return (
            <div className='buttons'>
                {settings.includes('save') &&
                <button className='main-button save' onClick={() => this.props.pushData('save')}>Save</button>}
                {settings.includes('publish') &&
                <button className='main-button publish' onClick={() => this.props.pushData('publish')}>Publish</button>}
            </div>
        )
    }
}

export default Buttons;