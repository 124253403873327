import {library} from '@fortawesome/fontawesome-svg-core';
import {faPlus, faWindowClose, faTrash, faFile, faPen, faSearch} from '@fortawesome/free-solid-svg-icons'
import {fileDelete} from "../Services/Api";

//Add fontawesome icons
library.add(
    faPlus,
    faWindowClose,
    faTrash,
    faFile,
    faPen,
    faSearch,
);

export const baseUrl = 'https://api.eurnekianschool.am/api';

export const languages = [
    {
        lngKey: 'hy',
        lngName: 'ARM'
    },
    {
        lngKey: 'en',
        lngName: 'ENG'
    },
    // {
    //     lngKey: 'ru',
    //     lngName: 'RUS'
    // },
    // {
    //     lngKey: 'es',
    //     lngName: 'ESP'
    // }
];

export function BlockDataType(blockType = '') {
    // console.log(blockType);

    if (blockType === 'listing') {
        return new BlockData(['Title', 'Description']);
    } else if (blockType === 'home-top') {
        return new BlockData(['Top-Block', 'Gallery']);
    } else if (blockType === 'home') {
        return new BlockData(['Testimonial', 'Gallery', 'Description']);
    } else if (blockType === 'faq-top') {
        return new BlockData(['Blocks']);
    } else if (blockType === 'bouard_of_trustees') {
        return new BlockData(['Title', 'Trustees']);
    } else if (blockType === 'trustees') {
        return new BlockData(['Trustees']);
    } else if (blockType === 'faq') {
        return new BlockData(['Faq']);
    } else if (blockType === 'our_team-top') {
        return new BlockData(['Top-Block', 'Gallery']);
    } else if (blockType === 'our_team') {
        return new BlockData(['Title', 'Workers']);
    } else if (blockType === 'advantages' || blockType === 'student_council') {
        return new BlockData(['In-Block', 'Icon']);
    } else if (blockType === 'advantages-top') {
        return new BlockData(['Top-Block', 'Image']);
    } else if (blockType === 'achievements-top') {
        return new BlockData(['In-Block', 'Status', 'Quote', 'Featured']);
    } else if (blockType === 'events-top') {
        return new BlockData(['In-Block', 'Status', 'Quote', 'Highlight', 'Date']);
    } else if (blockType.includes('-top')) {
        return new BlockData(['Top-Block']);
    } else {
        return new BlockData(['In-Block']);
    }
}

export function BlockData(dataTypes = []) {

    if (dataTypes.includes('Top-Block')) {
        this.title = {};
        this.desc = {};
        this.blocks = [];
    }

    if (dataTypes.includes('In-Block')) {
        this.title = {};
        this.desc = {};
        this.gallery = [];
    }

    if (dataTypes.includes('Blocks')) {
        this.blocks = [];
    }

    if (dataTypes.includes('Title')) {
        this.title = {};
    }

    if (dataTypes.includes('Description')) {
        this.desc = {};
    }

    if (dataTypes.includes('Gallery')) {
        this.gallery = [];
    }

    if (dataTypes.includes('Image')) {
        this.image = '';
    }

    if (dataTypes.includes('Icon')) {
        this.icon = undefined;
    }

    if (dataTypes.includes('Quote')) {
        this.quote = {};
    }

    if (dataTypes.includes('Status')) {
        this.status = "active";
    }

    if (dataTypes.includes('Highlight')) {
        this.highlight = {};
    }

    if (dataTypes.includes('Date')) {
        this.date = undefined;
    }

    if (dataTypes.includes('Featured')) {
        this.featured = 0;
    }

    if (dataTypes.includes('Testimonial')) {
        this.name = {};
        this.position = {};
    }

    if (dataTypes.includes('Faq')) {
        this.faqSection = [];
        this.question = {};
        this.answer = {};
    }

    if (dataTypes.includes('Workers')) {
        this.workers = [];
    }

    if (dataTypes.includes('Trustees')) {
        this.trustees = [];
    }
}

export function cleanData(object) {

    Object.entries(object).forEach(([key, val]) => {
        if (val && typeof val === 'object') {
            cleanData(val)
        } else if (val === null || val === undefined) delete object[key]
    });

    return object
}

export function cutString(input, len) {
    if (input) {
        return input.length > len ? `${input.substring(0, len)}...` : input;
    }
}

export function removeFilesFromServer(filesForDelete) {

    filesForDelete.forEach(file => {
        fileDelete(file).then(response => {
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    });
}

export function consolingImage() {
    const svg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 200 100" style="enable-background:new 0 0 200 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FEBB14;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#FF9800;}
	.st3{fill:#FF9000;}
	.st4{fill:#FFAA00;}
	.st5{fill:#E68100;}
	.st6{fill:#FF7200;}
	.st7{fill:#FFD900;}
</style>
<g id="Layer_2">
</g>
<g id="Layer_1">
	<path class="st0" d="M95.67,38.07h3.72v3.72l-3.11,1.75h-7.45l-3.11-1.75V26.78l3.11-1.75h7.45l3.11,1.75v3.72h-3.72v-2.34h-6.22
		v12.27h6.22V38.07z M116.89,41.79l-3.11,1.75h-8.18l-3.11-1.75V26.78l3.11-1.75h8.29l3.11,1.75L116.89,41.79z M106.21,28.16v12.27
		h6.99V28.16H106.21z M134.7,41.66l-3.11,1.75h-11.27V25.17h11.31l3.11,1.75L134.7,41.66z M124.02,28.3v12.03h6.99V28.3H124.02z
		 M138.12,43.4V25.17h3.74V43.4H138.12z M155.14,38.07h3.69v3.72l-3.11,1.75h-7.47l-3.11-1.75V26.78l3.11-1.75h7.47l3.11,1.75v3.72
		h-3.69v-2.34h-6.22v12.27h6.22V38.07z M161.99,34.19v-7.37l3.11-1.75h6.67l3.11,1.75v3.44h-3.69v-2.05h-5.5v4.31h6.04l3.11,1.75
		v7.53l-3.11,1.75h-6.94l-3.11-1.75v-3.51h3.69v2.05h5.67v-4.5h-6.04L161.99,34.19z"/>
	<path class="st1" d="M92.5,50.93c-0.44-0.13-0.9-0.2-1.36-0.2c-0.58-0.02-1.15,0.13-1.64,0.43c-0.36,0.22-0.59,0.6-0.6,1.02
		c-0.03,0.37,0.09,0.74,0.34,1.02c0.23,0.25,0.51,0.46,0.83,0.59c0.44,0.17,0.9,0.31,1.36,0.43c0.55,0.16,1.09,0.35,1.62,0.58
		c0.41,0.19,0.76,0.48,1.04,0.83c0.58,0.85,0.58,1.96,0,2.81c-0.32,0.42-0.76,0.74-1.25,0.92c-0.59,0.23-1.21,0.34-1.84,0.33
		c-0.69,0-1.37-0.14-2.01-0.41c-0.62-0.24-1.19-0.58-1.69-1.02l0.46-0.8c0.44,0.43,0.96,0.78,1.53,1.02
		c0.54,0.23,1.13,0.35,1.72,0.36c0.64,0.01,1.26-0.18,1.79-0.53c0.42-0.26,0.67-0.72,0.66-1.22c0.02-0.37-0.1-0.74-0.34-1.02
		c-0.23-0.26-0.51-0.46-0.83-0.59c-0.33-0.14-0.79-0.31-1.38-0.49c-0.55-0.16-1.08-0.35-1.61-0.57c-0.41-0.18-0.76-0.46-1.04-0.81
		c-0.3-0.41-0.45-0.9-0.42-1.4c-0.01-0.47,0.14-0.92,0.42-1.3c0.32-0.36,0.72-0.63,1.18-0.78c0.56-0.21,1.16-0.31,1.76-0.3
		c0.55,0,1.09,0.08,1.62,0.24c0.5,0.14,0.99,0.35,1.43,0.62l-0.42,0.82C93.41,51.26,92.96,51.06,92.5,50.93z M103.7,50.5
		c0.77,0.43,1.41,1.04,1.86,1.79c0.46,0.75,0.7,1.6,0.69,2.48c0.01,0.89-0.23,1.76-0.69,2.53c-1.46,2.43-4.64,3.23-7.1,1.79
		c-0.75-0.44-1.37-1.05-1.81-1.79c-0.46-0.75-0.7-1.61-0.69-2.49c-0.01-0.87,0.23-1.73,0.69-2.48c0.45-0.75,1.1-1.36,1.86-1.79
		c1.61-0.89,3.57-0.89,5.18,0L103.7,50.5z M99.04,51.25c-0.63,0.35-1.15,0.86-1.52,1.47c-0.37,0.62-0.57,1.33-0.56,2.05
		c0,0.72,0.19,1.43,0.56,2.05c0.36,0.62,0.89,1.14,1.52,1.5c0.63,0.36,1.34,0.55,2.07,0.55c0.73,0.01,1.44-0.19,2.07-0.55
		c0.63-0.36,1.15-0.87,1.51-1.49c0.37-0.62,0.56-1.33,0.56-2.05c0.01-0.72-0.19-1.43-0.56-2.05c-0.37-0.61-0.89-1.12-1.51-1.47
		c-0.63-0.36-1.34-0.55-2.07-0.54C100.39,50.71,99.67,50.9,99.04,51.25z M108.4,49.93h6.22v0.87h-5.18v3.73h4.64v0.86h-4.64v4.27
		h-0.97L108.4,49.93z M114.9,49.91h7.49v0.88h-3.26v8.87h-0.97v-8.87h-3.25V49.91z M135.88,49.91h1.04l-3.35,9.75h-1.04l-2.77-8.5
		l-2.81,8.53h-1.04l-3.27-9.78h1.04l2.81,8.73l2.8-8.73h1.04l2.81,8.73L135.88,49.91z M145.27,59.66l-1.17-2.59h-5.47l-1.16,2.59
		h-1.04l4.44-9.73h1.04l4.44,9.73H145.27z M139.05,56.2h4.7l-2.46-5.26L139.05,56.2z M154.45,59.66l-2.12-3.17h-3.4v3.17h-0.97
		v-9.73h3.71c1.02-0.07,2.04,0.23,2.86,0.84c0.7,0.59,1.08,1.47,1.04,2.38c0.03,0.73-0.17,1.45-0.59,2.05
		c-0.42,0.55-1.01,0.95-1.69,1.13l2.37,3.36L154.45,59.66z M151.66,55.65c0.78,0.05,1.55-0.17,2.18-0.63
		c0.52-0.46,0.8-1.14,0.76-1.83c0.05-0.68-0.23-1.34-0.76-1.78c-0.63-0.45-1.4-0.66-2.18-0.61h-2.7v4.86H151.66z M158.13,49.93h6.6
		v0.87h-5.63v3.43h5.03v0.86h-5.03v3.71h5.81v0.86h-6.79V49.93z M93.65,64.69c0.7,0.6,1.08,1.47,1.04,2.38
		c0.05,0.94-0.33,1.86-1.04,2.5c-0.8,0.63-1.81,0.94-2.84,0.88h-2.63v3.13H87.2v-9.73h3.64C91.85,63.79,92.84,64.09,93.65,64.69z
		 M92.95,68.94c0.53-0.46,0.81-1.14,0.77-1.83c0.04-0.68-0.24-1.34-0.76-1.78c-0.64-0.45-1.41-0.66-2.19-0.61h-2.6v4.86h2.62
		c0.78,0.05,1.55-0.18,2.18-0.63H92.95z M103.11,73.58l-2.12-3.17h-3.4v3.17h-0.97v-9.73h3.68c1.02-0.07,2.04,0.23,2.86,0.84
		c0.7,0.59,1.08,1.47,1.04,2.38c0.03,0.73-0.17,1.45-0.59,2.05c-0.42,0.55-1.01,0.95-1.69,1.13l2.33,3.33H103.11z M100.3,69.57
		c0.78,0.05,1.55-0.17,2.18-0.63c0.52-0.46,0.8-1.14,0.76-1.83c0.05-0.68-0.23-1.34-0.76-1.78c-0.63-0.45-1.4-0.66-2.18-0.61h-2.7
		v4.86H100.3z M113.59,64.46c0.77,0.43,1.41,1.04,1.86,1.79c0.46,0.75,0.7,1.6,0.69,2.48c0,0.88-0.24,1.74-0.69,2.49
		c-1.46,2.43-4.64,3.23-7.1,1.79c-0.75-0.44-1.37-1.05-1.81-1.79c-0.46-0.75-0.7-1.61-0.69-2.49c-0.01-0.87,0.23-1.73,0.69-2.48
		c0.45-0.75,1.1-1.36,1.86-1.79C110.02,63.57,111.98,63.57,113.59,64.46z M108.93,65.2c-0.63,0.35-1.15,0.86-1.52,1.47
		c-0.37,0.62-0.57,1.33-0.56,2.05c0,0.72,0.19,1.43,0.56,2.05c0.37,0.62,0.89,1.14,1.52,1.49c0.63,0.36,1.34,0.55,2.07,0.55
		c0.73,0.01,1.44-0.19,2.07-0.55c0.63-0.36,1.15-0.87,1.51-1.49c0.37-0.62,0.56-1.33,0.56-2.05c0.01-0.72-0.19-1.43-0.56-2.05
		c-0.37-0.61-0.89-1.12-1.51-1.47c-0.63-0.36-1.34-0.55-2.07-0.54c-0.72-0.01-1.44,0.17-2.07,0.51V65.2z M124.82,64.46
		c1.56,0.85,2.52,2.48,2.5,4.23c0.02,1.75-0.95,3.37-2.51,4.21c-0.8,0.43-1.69,0.66-2.6,0.64h-3.94v-9.7h3.96
		C123.13,63.83,124.02,64.03,124.82,64.46z M124.32,72.15c1.26-0.71,2.04-2.03,2.02-3.47c0-1.45-0.79-2.78-2.07-3.48
		c-0.63-0.36-1.35-0.54-2.07-0.53h-2.97v8.05h3.01c0.73,0.01,1.45-0.17,2.08-0.53V72.15z M131.03,71.96c1.32,1.11,3.26,1.11,4.58,0
		c0.58-0.64,0.87-1.49,0.82-2.34v-5.79h0.97v5.79c0.08,1.09-0.3,2.16-1.04,2.97c-1.74,1.43-4.26,1.43-6,0
		c-0.74-0.81-1.11-1.88-1.04-2.97v-5.79h0.97v5.79C130.23,70.47,130.49,71.31,131.03,71.96z M146.08,64.94
		c-1.21-0.51-2.6-0.43-3.74,0.21c-0.63,0.36-1.15,0.87-1.51,1.48c-0.37,0.62-0.56,1.33-0.56,2.05c0,0.72,0.19,1.43,0.56,2.05
		c0.36,0.62,0.88,1.13,1.51,1.49c0.63,0.36,1.34,0.55,2.07,0.55c0.55,0,1.09-0.11,1.6-0.32c0.53-0.19,1.02-0.47,1.44-0.84l0.59,0.62
		c-0.49,0.44-1.06,0.78-1.68,1.02c-0.62,0.26-1.28,0.4-1.95,0.4c-1.82,0.01-3.5-0.93-4.42-2.48c-0.45-0.75-0.69-1.61-0.68-2.49
		c-0.01-0.87,0.23-1.73,0.69-2.48c0.46-0.74,1.11-1.35,1.89-1.77c1.38-0.77,3.04-0.88,4.51-0.3c0.61,0.24,1.16,0.58,1.64,1.02
		l-0.58,0.66C147.05,65.46,146.59,65.15,146.08,64.94z M150.16,63.85h6.6v0.86h-5.63v3.48h5.03v0.86h-5.03v3.67h5.81v0.86h-6.79
		V63.85z M165.82,73.58l-2.12-3.17h-3.4v3.17h-0.97v-9.73H163c1.02-0.07,2.04,0.23,2.86,0.84c0.7,0.59,1.08,1.47,1.04,2.38
		c0.03,0.73-0.17,1.45-0.59,2.05c-0.42,0.55-1.01,0.95-1.69,1.13l2.33,3.33H165.82z M163,69.57c0.78,0.05,1.55-0.17,2.18-0.63
		c0.52-0.46,0.8-1.14,0.76-1.83c0.03-0.69-0.27-1.35-0.81-1.78c-0.63-0.45-1.4-0.66-2.18-0.61h-2.7v4.86H163z M173.66,64.85
		c-0.44-0.13-0.9-0.2-1.36-0.2c-0.57-0.03-1.13,0.11-1.63,0.4c-0.39,0.23-0.62,0.64-0.61,1.08c-0.03,0.37,0.09,0.74,0.34,1.02
		c0.23,0.25,0.51,0.46,0.83,0.59c0.44,0.19,0.9,0.35,1.36,0.48c0.55,0.16,1.09,0.35,1.62,0.58c0.41,0.19,0.76,0.48,1.04,0.83
		c0.3,0.42,0.45,0.92,0.42,1.43c0.01,0.5-0.15,0.98-0.45,1.38c-0.32,0.42-0.76,0.74-1.25,0.92c-0.59,0.21-1.21,0.31-1.83,0.29
		c-0.69,0-1.37-0.14-2.01-0.41c-0.62-0.24-1.19-0.58-1.69-1.02l0.46-0.8c0.44,0.43,0.96,0.78,1.53,1.02
		c0.54,0.23,1.13,0.35,1.72,0.36c0.63,0.03,1.25-0.12,1.79-0.45c0.42-0.26,0.67-0.72,0.66-1.22c0.02-0.37-0.1-0.74-0.34-1.02
		c-0.23-0.26-0.51-0.46-0.83-0.59c-0.33-0.14-0.79-0.31-1.38-0.49c-0.55-0.16-1.08-0.35-1.61-0.57c-0.41-0.18-0.76-0.46-1.04-0.81
		c-0.3-0.41-0.45-0.9-0.42-1.4c-0.01-0.47,0.14-0.92,0.42-1.3c0.31-0.39,0.71-0.69,1.18-0.86c0.56-0.21,1.16-0.31,1.76-0.3
		c0.55,0,1.09,0.08,1.62,0.24c0.5,0.14,0.99,0.35,1.43,0.62l-0.42,0.82C174.57,65.21,174.13,65,173.66,64.85L173.66,64.85z"/>
	<g>
		<path class="st2" d="M72.55,65.77l-21.84-6.12l0.02-8.41l21.84,6.12L72.55,65.77z"/>
		<path class="st3" d="M72.57,55.41l-21.83-6.12l1.5-0.39l21.83,6.12L72.57,55.41z"/>
		<path class="st4" d="M67.28,71.08l-21.83-6.12l5.26-5.31l21.84,6.12L67.28,71.08z"/>
		<path class="st3" d="M66.23,57.06l-21.84-6.12l6.35-1.65l21.83,6.12C72.57,55.41,66.23,57.06,66.23,57.06z"/>
		<path class="st2" d="M64.71,62.75l-21.84-6.12l0.01-3.35l21.83,6.12V62.75z M72.62,40.3l-21.84-6.12l0.02-8.41l21.83,6.12
			C72.63,31.89,72.62,40.3,72.62,40.3z"/>
		<path class="st2" d="M64.71,59.4l-21.83-6.12v-1.95l21.84,6.12L64.71,59.4z"/>
		<path class="st3" d="M64.72,57.45l-21.84-6.12l1.51-0.39l21.84,6.12L64.72,57.45z M66.26,41.95l-21.83-6.12l6.34-1.65l21.84,6.12
			L66.26,41.95z"/>
		<path class="st2" d="M64.76,44.29l-21.84-6.12l0.01-1.95l21.83,6.12C64.76,42.34,64.76,44.29,64.76,44.29z"/>
		<path class="st3" d="M54.45,74.42L32.61,68.3l12.84-3.34l21.83,6.12L54.45,74.42z"/>
		<path class="st5" d="M54.04,76.46L32.2,70.35l-0.34-0.17l21.83,6.12L54.04,76.46z"/>
		<path class="st2" d="M64.76,42.34l-21.83-6.12v-3.34l21.84,6.11C64.77,38.99,64.76,42.34,64.76,42.34z"/>
		<path class="st3" d="M57.05,64.74l-21.83-6.12l7.65-1.99l21.84,6.12C64.71,62.75,57.05,64.74,57.05,64.74z"/>
		<path class="st6" d="M72.63,31.89L50.8,25.77l-5.25-2.57l21.83,6.12L72.63,31.89z"/>
		<path class="st3" d="M67.39,27.37l-21.84-6.12l0.4-0.1l21.83,6.12C67.78,27.27,67.39,27.37,67.39,27.37z"/>
		<path class="st6" d="M53.69,76.3l-21.83-6.12l-5.25-2.57l21.83,6.12C48.44,73.73,53.69,76.3,53.69,76.3z"/>
		<path class="st6" d="M48.44,73.73l-21.83-6.12l-0.75-0.38l21.84,6.12C47.69,73.35,48.44,73.73,48.44,73.73z"/>
		<path class="st2" d="M47.69,73.35l-21.84-6.12l0.01-1.12l21.83,6.12V73.35z M55.54,67.07l-21.83-6.12l0.06-27.64l21.84,6.12
			C55.62,39.43,55.54,67.07,55.54,67.07z"/>
		<path class="st3" d="M55.62,39.43l-21.84-6.12l10.67-2.77l21.83,6.12C66.27,36.66,55.62,39.43,55.62,39.43z M54.54,30.71
			l-21.83-6.12l12.83-3.34l21.84,6.12C67.39,27.37,54.54,30.71,54.54,30.71z"/>
		<path class="st3" d="M54.14,30.81l-21.83-6.12l0.4-0.1l21.83,6.12L54.14,30.81z"/>
		<path class="st4" d="M53.8,31.16l-21.84-6.12l0.35-0.35l21.83,6.12L53.8,31.16z"/>
		<path class="st4" d="M48.53,36.46l-21.84-6.11l5.27-5.31l21.84,6.12L48.53,36.46z"/>
		<path class="st2" d="M47.69,72.23l-21.83-6.12l0.07-33.88l21.84,6.12C47.78,38.35,47.69,72.23,47.69,72.23z"/>
		<path class="st2" d="M47.78,38.35l-21.84-6.12v-1.12l21.84,6.12V38.35z"/>
		<path class="st4" d="M47.78,37.23l-21.84-6.12l0.75-0.76l21.84,6.11L47.78,37.23z"/>
		<path class="st7" d="M73.38,30.01l0.75,0.37v11.47l-1.5,0.39l-6.34,1.65l-1.51,0.39v-5.29l-7.65,2l-0.05,23.75l7.65-2v-5.29
			l1.51-0.39l6.34-1.65l1.5-0.39v11.54l-0.75,0.76l-5.25,5.26l-0.35,0.35l-0.4,0.1l-12.88,3.33l-0.4,0.11l-0.34-0.16l-5.22-2.59
			l-0.75-0.37v-1.12l0.08-33.88v-1.12l0.75-0.76l5.24-5.32l0.34-0.34l0.4-0.11l12.84-3.33l0.4-0.1l0.35,0.16L73.38,30.01z
			 M72.61,40.3v-8.41l-5.23-2.57l-12.83,3.33l-5.27,5.31l-0.08,33.88l5.25,2.58l12.83-3.33l5.27-5.31v-8.41l-6.32,1.64v5.29
			l-10.68,2.77l0.06-27.64l10.67-2.77v5.29l6.34-1.65"/>
	</g>
</g>
</svg>
`;

    const svgDataUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
    console.log('%c ', `
  background-image: url(${svgDataUrl});
  padding: 15% 50%;
  margin: 20px 0;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #4a0f69;
  border-radius: 10px;
`);
}